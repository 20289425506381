<template>
  <modal
    v-if="showModal"
    :show="showModal"
    modal-classes="modal-secondary"
    size="lg"
  >
    <form class="add-form pb-2">
      <div class="form-wrapper">
        <base-input
          v-if="!isPercent"
          type="number"
          :label="`${$t('AUCTIONS.AMOUNT')} (*)`"
          :placeholder="$t('AUCTIONS.AMOUNT')"
          v-model="propertyFee.auction_amount"
        >
        </base-input>
        <base-input
          v-if="isPercent"
          type="number"
          :label="`${$t('AUCTIONS.PERCENTAGE')} (*)`"
          :placeholder="$t('AUCTIONS.PERCENTAGE')"
          v-model="propertyFee.auction_amount"
        >
        </base-input>
        <validation-error :errors="apiValidationErrors.auction_amount" />
      </div>

      <div class="form-wrapper">
        <base-input
          :label="`${$t('PROPERTIES.TYPE_FEE')} (*)`"
          :placeholder="$t('PROPERTIES.TYPE_FEE')"
        >
          <el-select
            :clearable="true"
            class="select-primary pagination-select"
            :placeholder="$t('PROPERTIES.TYPE_FEE')"
            v-model="propertyFee.type_fee"
            @change="onFormChanged"
          >
            <el-option
              class="select-primary"
              v-for="(item, key) in TYPES"
              :key="key"
              :label="$t(`PROPERTIES.TYPE_${item}`)"
              :value="item"
            >{{ $t(`PROPERTIES.TYPE_${item}`) }}
            <el-tooltip :content="$t(`PROPERTIES.TYPE_DESCRIPTION_${item}`)" placement="top">
              <a
                type="text"
                href="#"
                class="table-action info-type-icon"
                data-toggle="tooltip"
                style="cursor: pointer"
              >
                <i class="fa fa-question"></i>
              </a>
            </el-tooltip>
            </el-option>
          </el-select>
        </base-input>
        <validation-error :errors="apiValidationErrors.type_fee" />
      </div>
      <div class="form-wrapper">
        <base-input
          :label="`${$t('PROPERTIES.DATE_FEE')}`"
          :placeholder="$t('PROPERTIES.DATE_FEE')"
          v-model="propertyFee.date"
          @change="onFormChanged"
        >
          <flat-picker
            :placeholder="$t('PROPERTIES.DATE_FEE')"
            :config="{
              allowInput: true,
              enableTime: false,
              type: 'date',
              minDate: 'today',
              locale: $flatPickrLocale(),
              disable: [
                function (date) {
                  return date.getDay() === 0;
                },
              ],
            }"
            class="form-control datepicker"
            v-model="propertyFee.date"
            @on-change="onFormChanged()"
          >
          </flat-picker>
        </base-input>
        <validation-error :errors="apiValidationErrors.date" />
      </div>
      <div class="form-wrapper full">
        <!-- Excerpt -->
        <base-input
          :label="`${$t('COMMON.EXCERPT')}`"
          :placeholder="$t('COMMON.EXCERPT')"
        >
          <html-editor v-model="propertyFee.description" @change="onFormChanged()">
          </html-editor>
        </base-input>
        <validation-error :errors="apiValidationErrors.description" />
      </div>
    </form>

    <template slot="footer">
      <button
        type="submit"
        class="btn btn-primary"
        @click="checkApplyFee"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("COMMON.SUBMIT") }}
      </button>

      <button
        type="button"
        class="btn btn-link ml-auto"
        @click="closePropertyFeeModal"
        :disabled="loading"
      >
        {{ $t("COMMON.CLOSE") }}
      </button>
    </template>
  </modal>
</template>

<script>
import swal from "sweetalert2";
import { Button, Tooltip } from "element-ui";
import { cloneDeep } from "lodash";
import formMixin from "@/mixins/form-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";
import ValidationError from "@/components/ValidationError.vue";
import { mapGetters } from "vuex";
import { Option, Select } from "element-ui";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { TYPES } from "@/constants/propertyFees";
import {
  PROPERTY_STATUS,
  PROPERTY_TAXES,
  PROPERTY_TYPES,
  PROPERTY_ORIGINS,
  STATUS_ACTIVE,
  STATUS_APPROVED,
  STATUS_CANCEL,
  STATUS_CONFIRMED,
  STATUS_PENDING,
  STATUS_SOLD,
} from "@/constants/properties";

export default {
  name: "auction-apply-fee",

  components: {
    ValidationError,
    HtmlEditor,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    [Tooltip.name]: Tooltip,
    flatPicker,
  },

  mixins: [formMixin, requestErrorMixin],

  props: ["auction", "propertyFee", "showModal", "loading", "formErrors"],

  data() {
    return {
      auctionFees: [],
      lastFee: {},
      TYPES,
      showApplyFeeModal: this.showModal,
      currentItem: null,
      isPercent: false,
      amountByPropertyValue: 0,
    };
  },

  computed: {
    ...mapGetters("propertyFees", ["list"]),
  },

  methods: {
    onFormChanged() {
      if(this.propertyFee.type_fee == 'PERCENTAGE'){
        this.isPercent = true;
        if(parseFloat(this.propertyFee.auction_amount) > 100){
          this.propertyFee.auction_amount = 0;
        }
      }else{
        this.isPercent = false;
      }
      this.$emit("formChanged");
    },
    closePropertyFeeModal() {
      this.$emit("onCloseApplyFeeModal", true);
    },

    async checkApplyFee() {
      let propertyFeeData = this.propertyFee;
      swal.fire({
        title: "Chargement",
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });
      
      let params = {
        ...(this.sort ? { sort: "created_at" } : {}),
        filter: {
          auctionId: this.auction.id,
          status: STATUS_CONFIRMED,
        },
        include: "organization,allowedLocations,updatedBy,createdBy",
      };
      await this.$store.dispatch("properties/list", params);
      this.properties = this.$store.getters["properties/list"];
      
      let amountAuction = propertyFeeData.auction_amount;
      if(propertyFeeData.type_fee == 'BY_PROPERTY'){
        let propertiesCount = this.properties.length;
        this.amountByPropertyValue = propertyFeeData.auction_amount/this.properties.length;
      }
      //this.$emit("onAppliedFeeUpdated", true);
      
      let amountByPropertyText = '';
      if(propertyFeeData.type_fee == 'BY_PROPERTY'){
        amountByPropertyText = '<p>'+this.$t("AUCTIONS.FEE_AMOUNT_BY_PROPERTY")+this.amountByPropertyValue.toFixed(2)+'$</p>';
      }
      if(propertyFeeData.type_fee == 'PERCENTAGE'){
        amountByPropertyText = '<p>'+this.$t("AUCTIONS.FEE_AMOUNT_BY_PROPERTY")+propertyFeeData.auction_amount+'</p>';
      }

      swal.close();
      const confirmation = await swal.fire({
        title: this.$t("AUCTIONS.APPLY_FEES"),
        html: '<p>'+this.$t("AUCTIONS.TOTAL_PROPERTIES_APPLIED_FEE")+' '+ this.properties.length+' '+this.$t("AUCTIONS.PROPERTIES")+'</p>'+amountByPropertyText,
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.VALIDATE"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          this.submitApplyFeeForm();
        }
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    submitApplyFeeForm() {
      if(!this.isPercent && (this.propertyFee.auction_amount == null || this.propertyFee.auction_amount == '' || parseFloat(this.propertyFee.auction_amount) <= 0)){
        this.$notify({
          type: "danger",
          message: this.$t("AUCTIONS.APPLIED_FEE_AMOUNT_REQUIRED"),
        });
        return;
      }
      if(this.isPercent && (this.propertyFee.auction_amount == null || this.propertyFee.auction_amount == '' || parseFloat(this.propertyFee.auction_amount) <= 0)) {
        this.$notify({
          type: "danger",
          message: this.$t("AUCTIONS.APPLIED_FEE_PERCENT_REQUIRED"),
        });
        return;
      }
      if(this.propertyFee.type_fee == null || this.propertyFee.type_fee == ''){
        this.$notify({
          type: "danger",
          message: this.$t("AUCTIONS.APPLIED_FEE_TYPE_REQUIRED"),
        });
        return;
      }
      if(this.propertyFee.date == null || this.propertyFee.date == ''){
        this.$notify({
          type: "danger",
          message: this.$t("AUCTIONS.APPLIED_FEE_DATE_REQUIRED"),
        });
        return;
      }
      if(this.isPercent && parseFloat(this.propertyFee.auction_amount) > 100){
        this.$notify({
          type: "danger",
          message: this.$t("AUCTIONS.APPLIED_FEE_PERCENT_LIMIT"),
        });
        return;
      }
      const PropertyFeeData = cloneDeep(this.propertyFee);
      this.$emit("onSubmitApplyFeeForm", PropertyFeeData);
    },

    deleteAuctionFee(auctionFeeId) {
      this.$emit("onDeleteAuctionFee", auctionFeeId);
    },
    showDescription(type){
      this.currentItem = type;
    }
  },

  mounted() {},

  watch: {
    list: {
      handler(newList) {
        this.auctionFees = newList;
        this.resetApiValidation();
        if (newList.length > 0) {
          this.lastFee = this.auctionFees[0];
          this.$emit("updateAuctionFeeModel", newList[0]);
        }
      },
      immediate: true,
    },

    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  .info-type-icon{
    float: right;
    font-size: 10px;
  }
</style>
